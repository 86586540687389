<template>
  <div>
    <base-text-field
      append-icon="mdi-magnify"
      label="Search articles"
    />

    <base-info-card title="About Us">
      <base-img
        src="https://pbs.twimg.com/media/Dr7NLz9UwAAHni6?format=jpg"
        class="mb-4"
        color="grey lighten-1"
        height="196"
        tile
        width="100%"
      />

      <base-body>
        Hello, we are the Carter family. Started from a relationship at OSU, our family is strong and has been growing. We strive to fill the world with positivity and love every day.
      </base-body>
    </base-info-card>
  </div>
</template>

<script>
  export default {
    name: 'NewsSearch',
  }
</script>
